import React from "react";
import { Link } from "react-router-dom";

import keyBenefits from "assets/img/key-benefits.png";
import Breadcrumb from "components/Breadcrumb";
import Logo from "components/Logo";

const About: React.FunctionComponent<{}> = () => (
  <section className="about__container">
    <div className="about__body">
      <Breadcrumb
        className="about__breadcrumb"
        theme="white"
        crumbs={[
          {
            to: "/",
            crumb: "Return to Login",
          },
        ]}
      />
      <Link to="/signin">
        <Logo dropShadow={true} className="about__logo" />
      </Link>
      <h1 className="about__header about__text--white about__text--center">
        Optimizing the Validation & Reset Process
      </h1>
      <iframe
        title="About"
        className="about__movie"
        src="https://www.youtube.com/embed/RahPaZIvkq8"
        allow="fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
      <p className="about__text about__text--white">
        BrewBox360 is a desktop and mobile website that allows you to view, validate and share
        planograms from anywhere with WI-FI, internet or mobile network access. BrewBox360 inspires
        partnership through streamlining communication and enhancing the overall experience of all
        participants of the reset process.
      </p>
      <h2 className="about__header about__text--white about__text--center">Key Benefits:</h2>
      <img className="about__image" src={keyBenefits} alt="" />
      <p className="about__text about__text--white about__text--center">
        Interested in more information, click
        <a href="mailto:support@brewbox360.com" target="_top">
          {" "}
          HERE!
        </a>
      </p>
      <p className="about__text--white about__text--center">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.anheuser-busch.com/terms-conditions.html"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.anheuser-busch.com/privacy-policy.html"
        >
          Privacy Policy
        </a>
        .
      </p>
    </div>
  </section>
);

export default About;
