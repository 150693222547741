import IRISLogo from "assets/img/IRIS-logo-vertical.png";
import downloadSvg from "assets/img/download.svg";
import loadingSvg from "assets/img/loading.svg";
import cx from "classnames";
import Button from "components/Button";
import { Card, CardList } from "components/Card";
import DataTable, { parseSortParam } from "components/DataTable";
import Dropdown from "components/Dropdown";
import Form from "components/Form";
import Icon from "components/Icon";
import Page from "components/Page";
import { Tooltip } from "components/Tooltip";
import { RouteProps } from "containers/App";
import WholesalerFilter, { FilterOptions } from "containers/WholesalerFilter";
import Constants from "helpers/constants";
import { getItemJson, removeItem, setItem } from "helpers/storage";
import { DirFile, DirFileVersion, DirFilesQueryParams } from "interfaces/dir-file";
import { PaginatedQuery } from "interfaces/paginated-query";
import { ReactSelectObject } from "interfaces/select";
import { isNumber } from "lodash";
import kebabCase from "lodash/kebabCase";
import memoize from "lodash/memoize";
import moment from "moment";
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import { Column } from "react-table-latest";
import { State } from "state";
import {
  DIRDownloadSearchFields,
  DIRFileStatus,
  EventTypes,
  createAnalyticsEvent,
} from "state/analytics";
import { IRIS_FILE_LIMIT, fetchZipForIrisDownload } from "state/batch_download";
import { clearDIRFiles, fetchDIRFiles, fetchDIRFilesCount, initDIRFiles } from "state/dir-files";
import ButtonWithConfirmation, { DialogIcon } from "../components/ButtonWithConfirmation";

type DirFileTableFields = DirFileVersion &
  Omit<DirFile<2>, "versions"> & {
    version: number;
    date: string;
    subRows: [];
  };

interface DirDownloadsState {
  currentWholesalerName: string;
  currentWholesalerNumber: string;
  deselectedIds: string[];
  disableRepeatDownload?: boolean;
  downloadMessage?: string;
  fields: Set<string>;
  filesPageIndex?: number;
  filesPageSize: number;
  filesSort?: string;
  isLoadingFiles: boolean;
  isSearchInProgress?: boolean;
  isSelectionInclusive: boolean;
  selectAllActive: boolean;
  selectedSurveyReasons: ReactSelectObject[];
  query: typeof defaultQuery;
  searchError: boolean;
  selectedIds: string[];
  selectionTotalCount: number;
  resetTable: boolean;
}

type DownloadsProps = PropsFromRedux & RouteProps;

export const IRIS_DOWNLOADS_PAGE_SIZE = 25;
export const IRIS_DOWNLOADS_DEFAULT_SORT = "created_at.desc";

const surveyReasonOptions = [
  { label: "Monthly Capture", value: "Monthly Capture" },
  { label: "Planogram Update Needed", value: "Planogram Update Needed" },
  { label: "Reset Request", value: "Reset Request" },
  { label: "Reset Completed", value: "Reset Completed" },
  { label: "No Cooler Door Capture", value: "No Cooler Door Capture" },
];

const defaultPaginationParams: PaginatedQuery = {
  sort: IRIS_DOWNLOADS_DEFAULT_SORT,
  size: IRIS_DOWNLOADS_PAGE_SIZE,
  page: 0,
};

const defaultQuery: DirFilesQueryParams & PaginatedQuery = {
  address: "",
  city: "",
  page: 0,
  size: IRIS_DOWNLOADS_PAGE_SIZE,
  sort: IRIS_DOWNLOADS_DEFAULT_SORT,
  state: "",
  storeName: "",
  wacn: "",
  wholesalerNumber: "",
  selectedSurveyReasonsValues: [],
  selectedSurveyReasons: [],
  zip: "",
  createdDate: "",
  ...defaultPaginationParams,
};

const defaultSelectionState = {
  isSelectionInclusive: false,
  deselectedIds: [],
  selectedIds: [],
  selectionTotalCount: 0,
  selectAllActive: false,
  resetTable: false,
};

const defaultState: DirDownloadsState = {
  currentWholesalerName: "",
  currentWholesalerNumber: "",
  fields: new Set(),
  filesPageIndex: 0,
  filesPageSize: IRIS_DOWNLOADS_PAGE_SIZE,
  filesSort: IRIS_DOWNLOADS_DEFAULT_SORT,
  isLoadingFiles: true,
  isSearchInProgress: false,
  query: defaultQuery,
  selectedSurveyReasons: [],
  searchError: false,
  ...defaultSelectionState,
};

const getTableDataFromFiles = memoize((files: DirFile<2>[]): DirFileTableFields[] => {
  const fileVersions = files.map((dirFile) => {
    const { store_name, store_address, wacn, versions, survey_reason } = dirFile;
    const commonProps = {
      store_name,
      store_address,
      wacn,
      survey_reason,
    };
    const previousVersions = versions.slice(1).map((v, i) => {
      commonProps.survey_reason = v.survey_reason;
      return {
        ...commonProps,
        ...v,
        date: v.created_at,
        version: (i + 1) * -1,
        subRows: false,
      };
    });

    commonProps.survey_reason = versions[0].survey_reason;
    const tableRow = {
      ...commonProps,
      ...versions[0],
      date: versions[0].created_at,
      version: 0,
      subRows: previousVersions.length > 0 ? previousVersions : false,
    };
    return tableRow as DirFileTableFields;
  });
  return fileVersions;
});

class DirDownloads extends React.Component<DownloadsProps, DirDownloadsState> {
  private initialFiles = [];

  private tableColumnsConfig = [
    {
      Header: "Store Name",
      accessor: "store_name",
      dataQa: "store-name",
      width: 250,
    },
    {
      Header: "Address",
      accessor: "store_address",
      dataQa: "store-address",
      width: 340,
    },
    {
      Header: "WACN",
      accessor: "wacn",
      dataQa: "wacn",
      width: 110,
    },
    {
      Header: "Survey Reason",
      accessor: "survey_reason",
      dataQa: "survey-reason",
      width: 250,
    },
    {
      Header: "Version",
      accessor: "version",
      dataQa: "version",
      disableSortBy: true,
      Cell: (meta) => {
        const originalRow = meta.row.original;
        const value = meta.value;
        return (
          <div aria-label={`${originalRow.store_name} PSA File Version`}>
            {value < 0 ? "Previous" : "Current"}
          </div>
        );
      },
      width: 110,
    },
    {
      Header: "Created Date",
      accessor: "created_at",
      dataQa: "date",
      Cell: (meta) => {
        const value = meta.value;
        return formatCreatedDate(value);
      },
      width: 130,
    },
    {
      Header: "PSA File",
      accessor: "blob_url",
      dataQa: "psa-file",
      disableSortBy: true,
      Cell: (meta) => {
        const {
          row: {
            original: { store_name: storeName, version },
          },
        } = meta;
        return (
          <button
            title={`Download PSA for ${storeName}`}
            className="download-button"
            data-qa="download-file-button"
            onClick={(e) => {
              this!.handleDownloadClick(version, meta.value);
            }}
          >
            <Icon src={downloadSvg} />
          </button>
        );
      },
      width: 100,
    },
    {
      Header: "Survey Results and Images",
      accessor: "survey_url",
      dataQa: "survey-results",
      disableSortBy: true,
      Cell: (meta) => {
        const {
          row: {
            original: { store_name: storeName },
          },
          value: surveyUrl,
        } = meta;

        return surveyUrl ? (
          <button
            className="go-spot-check-survey"
            data-qa="go-spot-check-survey-link"
            title={`Go Spot Check Survey results for ${storeName}`}
            onClick={(e) => {
              this!.goSpotCheckClick(e, surveyUrl);
            }}
          >
            GoSpotCheck Survey
          </button>
        ) : (
          <div className="current-file-data">
            <span>No Survey Results Available.</span>
          </div>
        );
      },
      width: 220,
    },
  ] as (Column<DirFileTableFields> & { dataQa: string })[];

  public state = defaultState;

  public componentDidMount() {
    this.props.initDIRFiles();

    const query = getItemJson(Constants.IRIS_DOWNLOADS_QUERY);
    if (query) {
      this.setState({
        query: {
          ...query,
          ...defaultPaginationParams,
        },
      });
      this.setDIRFiles(query);
    } else {
      this.setDIRFiles(defaultQuery);
    }
  }

  componentDidUpdate(_prevProps: DownloadsProps, prevState: DirDownloadsState) {
    const { page: prevPage, size: prevSize, sort: prevSort } = prevState.query;
    const { page: currPage, size: currSize, sort: currSort } = this.state.query;
    const shouldFetchNewPage =
      prevPage !== currPage || prevSize !== currSize || prevSort !== currSort;
    if (shouldFetchNewPage) {
      this.props.fetchDIRFiles(this.state.query);
    }

    if (this.state.resetTable) {
      this.setState({
        ...defaultSelectionState,
      });
    }
  }

  public emailCount() {
    const fileCount = this.state.selectionTotalCount || 0;
    return Math.ceil(fileCount / IRIS_FILE_LIMIT);
  }

  public setDIRFiles(query: DirFilesQueryParams) {
    const { currentWholesalerNumber, currentWholesalerName, fields } = this.state;

    query = {
      ...query,
      ...defaultPaginationParams,
    };

    this.setState({
      isSearchInProgress: true,
      ...defaultSelectionState,
    });

    this.props.fetchDIRFiles(query).then(
      () => {
        const { files = [] } = this.props;

        this.setState({
          isSearchInProgress: false,
          disableRepeatDownload: false,
          downloadMessage: "",
        });

        this.props.createAnalyticsEvent({
          event: EventTypes.DIR_DOWNLOADS_SEARCH,
          eventType: "Action",
          wholesalerName: currentWholesalerName,
          wholesalerNumber: currentWholesalerNumber,
          searchResults: files.length,
          searchFieldsList: [...Array.from(fields)] as DIRDownloadSearchFields[],
          searchAction: "Search",
        });
      },
      (reason) => {
        this.setState({
          searchError: true,
          isLoadingFiles: false,
        });
      }
    );
  }

  public handleSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    setItem(Constants.IRIS_DOWNLOADS_QUERY, this.state.query);
    this.setDIRFiles(this.state.query);
    this.setState({ resetTable: true });
  };

  public handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    // reset form and inputs
    this.setState({
      query: defaultQuery,
      fields: new Set(),
      resetTable: true,
    });
    this.setState({ selectedSurveyReasons: [] });
    removeItem(Constants.IRIS_DOWNLOADS_QUERY);
    this.props.clearDIRFiles();
    this.props.createAnalyticsEvent({
      event: EventTypes.DIR_DOWNLOADS_SEARCH,
      eventType: "Action",
      searchAction: "Clear",
    });
  };

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      query: {
        ...this.state.query,
        [e.currentTarget.name]: e.currentTarget.value,
      },
      fields: this.state.fields.add(e.currentTarget.placeholder),
    });
  };

  public goSpotCheckClick = (e: React.MouseEvent<HTMLButtonElement>, surveyUrl: string) => {
    const { currentWholesalerName, currentWholesalerNumber } = this.state;
    this.props.createAnalyticsEvent({
      event: EventTypes.DIR_DOWNLOAD_FILES,
      eventType: "Action",
      wholesalerName: currentWholesalerName,
      wholesalerNumber: currentWholesalerNumber,
      downloadFileCount: 1,
      goSpotCheckSurveyLink: surveyUrl,
    });
    this.props.createAnalyticsEvent({
      event: EventTypes.DIR_DOWNLOAD_IRIS_SURVEY,
      eventType: "Action",
      wholesalerName: currentWholesalerName,
      wholesalerNumber: currentWholesalerNumber,
      downloadFileCount: 1,
      goSpotCheckSurveyLink: surveyUrl,
    });

    return window.open(surveyUrl);
  };

  public handleBatchDownload = (e: React.MouseEvent<HTMLFormElement>) => {
    const {
      selectedIds,
      deselectedIds,
      currentWholesalerName,
      currentWholesalerNumber,
      selectionTotalCount,
      selectAllActive,
    } = this.state;

    const deselectedFileIds = deselectedIds.map((id) => ({ id: id }));
    const selectedFileIds = selectedIds.map((id) => ({ id: id }));

    this.props.createAnalyticsEvent({
      event: EventTypes.DIR_DOWNLOAD_FILES,
      eventType: "Action",
      wholesalerName: currentWholesalerName,
      wholesalerNumber: currentWholesalerNumber,
      downloadFileCount: selectionTotalCount,
    });

    this.setState({
      ...this.state,
      disableRepeatDownload: true,
    });

    this.props
      .fetchZipForIrisDownload(
        this.state.query,
        selectedFileIds,
        deselectedFileIds,
        selectAllActive
      )
      .then(() => {
        if (this.props.hasError) {
          return;
        }
        this.setState({
          ...this.state,
          downloadMessage: Constants.DOWNLOAD_FILES_SUCCESS_MESSAGE,
          disableRepeatDownload: false,
        });
      })
      .catch(() =>
        this.setState({
          ...this.state,
          downloadMessage: Constants.DOWNLOAD_FILES_ERROR_MESSAGE,
          disableRepeatDownload: false,
        })
      );
  };

  public table: any;

  public handleDownloadClick = (version: number, url: string) => {
    let filePathArray;
    let fileName;
    const { currentWholesalerName, currentWholesalerNumber } = this.state;
    filePathArray = url.split("/");

    if (filePathArray[filePathArray.length - 1].indexOf(".") !== -1) {
      fileName = filePathArray[filePathArray.length - 1].replace(/%20/g, " ");
    }
    if (fileName) {
      this.props.createAnalyticsEvent({
        event: EventTypes.DIR_DOWNLOAD_FILES,
        eventType: "Action",
        wholesalerName: currentWholesalerName,
        wholesalerNumber: currentWholesalerNumber,
        downloadFileCount: 1,
        downloadFileStatus: version < 0 ? DIRFileStatus.PREVIOUS : DIRFileStatus.CURRENT,
        downloadFileName: fileName,
      });

      this.props.createAnalyticsEvent({
        event: EventTypes.DIR_DOWNLOAD_IRIS_PSA,
        eventType: "Action",
        wholesalerName: currentWholesalerName,
        wholesalerNumber: currentWholesalerNumber,
        downloadFileCount: 1,
        downloadFileStatus: version < 0 ? DIRFileStatus.PREVIOUS : DIRFileStatus.CURRENT,
        downloadFileName: fileName,
      });
    }
    return window.open(url);
  };

  public renderDownloadStatus() {
    if (this.props.isLoadingDownloads) {
      return <img className="spin-right" src={loadingSvg} alt="Loading Icon" />;
    } else if (this.props.hasError) {
      return (
        <span className="download-error error">
          {Constants.DOWNLOAD_FILES_DOWNLOAD_FAILED_MESSAGE}
        </span>
      );
    } else if (this.state.downloadMessage) {
      return <span className="download-message">{this.state.downloadMessage}</span>;
    }
    return;
  }

  public renderForm() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group title="Store Details">
          <Form.Input
            type="text"
            placeholder="Store Name"
            data-qa="store-name-field"
            value={this.state.query.storeName}
            name="storeName"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="WACN"
            data-qa="wacn-field"
            value={this.state.query.wacn}
            name="wacn"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group title="Address">
          <Form.Input
            type="text"
            placeholder="Address"
            data-qa="address-field"
            value={this.state.query.address}
            name="address"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="City"
            data-qa="city-field"
            value={this.state.query.city}
            name="city"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="State"
            data-qa="state-field"
            value={this.state.query.state}
            name="state"
            onChange={this.handleChange}
          />
          <Form.Input
            type="text"
            placeholder="Zip Code"
            data-qa="zip-code-field"
            value={this.state.query.zip}
            name="zip"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group title="Survey created date">
          <Form.Input
            type="date"
            placeholder="Created Date"
            data-qa="created-date-field"
            value={this.state.query.createdDate}
            name="createdDate"
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.ButtonGroup>
          <Form.CancelButton onClick={this.handleClear} data-qa="clear-button">
            Clear
          </Form.CancelButton>
          <Form.ConfirmButton data-qa="search-button">Search</Form.ConfirmButton>
        </Form.ButtonGroup>
        {this.state.searchError && (
          <div className="search-error" aria-live="polite">
            Something went wrong. Please try searching again.
          </div>
        )}
      </Form>
    );
  }

  public render() {
    const { isLoadingFiles, filesPageCount, filesPageIndex } = this.props;

    const {
      disableRepeatDownload,
      query: { wholesalerNumber, sort: filesSort },
      isSearchInProgress,
      selectedIds,
      selectAllActive,
      resetTable,
    } = this.state;

    let files: DirFileTableFields[] =
      this.props.files && this.props.files.length > 0
        ? getTableDataFromFiles(this.props.files)
        : this.initialFiles;

    const shouldResetTable =
      (isSearchInProgress && filesPageIndex === 0 && files.length > 0) || resetTable;

    return (
      <Page className="dir-page mt-negative">
        <h1 className="flex flex-v-center">
          IRIS Downloads{" "}
          <div className="flex-item-right">
            {<img style={{ height: "60px" }} src={IRISLogo} alt="Powered By IRIS" />}
          </div>
        </h1>
        <CardList className="mt-0">
          <Card>
            <Card.Header>
              <div className="downloads-header">
                <div data-qa="select-project">
                  <WholesalerFilter
                    value={wholesalerNumber}
                    onChange={({ number, name }: FilterOptions) => {
                      this.setState({
                        currentWholesalerNumber: number,
                        currentWholesalerName: name,
                        query: {
                          ...this.state.query,
                          wholesalerNumber: number,
                        },
                      });
                    }}
                  />
                </div>
                <div data-qa="select-project">
                  <Dropdown
                    containerStyles={{ width: "400px", minWidth: 230, marginLeft: 10 }}
                    placeholder="Select Survey Types"
                    options={surveyReasonOptions}
                    onChange={(selectedSurveyReasons: ReactSelectObject[]) => {
                      this.setState({ selectedSurveyReasons });
                      const selectedSurveyReasonsValues = selectedSurveyReasons.map(
                        (obj) => obj.value
                      );
                      this.setState({
                        query: {
                          ...this.state.query,
                          selectedSurveyReasonsValues: selectedSurveyReasonsValues,
                          selectedSurveyReasons: selectedSurveyReasons,
                        },
                      });
                    }}
                    isDisabled={false}
                    value={this.state.selectedSurveyReasons}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    isSearchable={true}
                  />
                </div>
              </div>
            </Card.Header>
            <Card.Body>{this.renderForm()}</Card.Body>
          </Card>
          <Card>
            <Card.Header className="table-header">
              Results
              <div className="download-all-button">
                {this.renderDownloadStatus()}
                {this.emailCount() > 1 ? (
                  <ButtonWithConfirmation
                    typeConfiguration={{ secondary: true }}
                    onPrimaryAction={this.handleBatchDownload}
                    disabled={
                      (selectedIds.length < 1 && !selectAllActive) ||
                      this.props.isLoadingDownloads ||
                      disableRepeatDownload
                    }
                    openDialogButtonContent={`Download Selected Files (${this.state.selectionTotalCount})`}
                    primaryButtonContent="Download"
                    secondaryButtonContent="Cancel"
                    primaryContent={() => <p>Are you sure you wish to download all files?</p>}
                    secondaryContent={() => (
                      <div className="items-center">
                        <p>
                          Due to the volume of files, this email will be sent as {this.emailCount()}{" "}
                          separate emails.
                        </p>
                      </div>
                    )}
                    icon={DialogIcon.CAUTION}
                    getIconProps={{ large: true }}
                  />
                ) : (
                  <Button
                    data-qa="download-all-files-button"
                    onClick={this.handleBatchDownload}
                    secondary={true}
                    hasTooltip={disableRepeatDownload}
                    disabled={
                      (selectedIds.length < 1 && !selectAllActive) ||
                      this.props.isLoadingDownloads ||
                      disableRepeatDownload
                    }
                  >
                    <Tooltip message="Please select a different set of files to download." />
                    {`${
                      selectedIds.length < 1 && !selectAllActive
                        ? "Select files to download"
                        : `Download Selected Files (${this.state.selectionTotalCount})`
                    }`}
                  </Button>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <DataTable
                columns={this.tableColumnsConfig}
                data={files}
                reset={shouldResetTable}
                isLoading={isLoadingFiles}
                sortable
                paginated
                manualPagination
                pageCount={filesPageCount}
                rowSelectable
                initialState={{
                  pageSize: IRIS_DOWNLOADS_PAGE_SIZE,
                  pageIndex: filesPageIndex,
                  sortBy: parseSortParam(filesSort),
                }}
                renderEmpty="No Results, Check Search Fields and Try Again"
                getRowId={(row) => String(row.id)}
                getRowProps={(baseProps, { row }) => {
                  const originalData = row.original as any;
                  return {
                    ...baseProps,
                    className: cx({
                      "previous-file": originalData.version < 0,
                    }),
                    id: row.id,
                  };
                }}
                getHeaderProps={(baseProps, { column }) => {
                  return {
                    ...baseProps,
                    className: cx(`table-header-${kebabCase(column.id)}`, {}),
                  };
                }}
                getCellProps={(baseProps, { cell }) => {
                  return {
                    ...baseProps,
                    className: cx(`table-cell-${kebabCase(cell.column.id)}`, {}),
                  };
                }}
                selectAllRowFilter={(row) => {
                  // Do not select subrows, which are past file versions
                  return row.depth === 0;
                }}
                onRowSelect={({ isInclusive, selectedRowIds, deselectedRowIds, action }) => {
                  switch (action.type) {
                    case "selectAll":
                      fetchDIRFilesCount(this.state.query)
                        .then((response) => {
                          const count = parseInt(response.data.fileCount, 10);
                          if (isNumber(count)) {
                            this.setState({
                              selectionTotalCount: count,
                            });
                          }
                        })
                        .finally(() => {
                          this.setState({ selectAllActive: true });
                        });

                      break;

                    case "deselectAll":
                      // The default for batch download is all files, which requires
                      // the query to use exclusive mode.
                      this.setState({ selectAllActive: false, selectionTotalCount: 0 });
                      isInclusive = false;
                      break;

                    case "select":
                      if (this.state.selectedIds.length === 0 && !this.state.selectAllActive) {
                        this.setState({ selectionTotalCount: 1 });
                      } else {
                        this.setState({ selectionTotalCount: this.state.selectionTotalCount + 1 });
                      }

                      // Select all, deselect each row, then reselect each row
                      if (selectedRowIds.length === 0) {
                        this.setState({ selectAllActive: true });
                      }
                      break;

                    case "deselect":
                      this.setState({ selectionTotalCount: this.state.selectionTotalCount - 1 });
                      break;

                    default:
                      break;
                  }
                  this.setState({
                    isSelectionInclusive: isInclusive,
                    selectedIds: selectedRowIds,
                    deselectedIds: deselectedRowIds,
                  });
                }}
                onPageChange={(page: number, size: number, sort: string) => {
                  if (
                    page !== this.state.query.page ||
                    size !== this.state.query.size ||
                    sort !== this.state.query.sort
                  ) {
                    this.setState({
                      query: {
                        ...this.state.query,
                        sort,
                        page,
                        size,
                      },
                    });
                  }
                }}
              />
            </Card.Body>
          </Card>
        </CardList>
      </Page>
    );
  }
}
// Always display the date as though the viewer was in UTC offset -6h
// (MDT/Denver during DST, and CST/Chicago not during DST). This is to
// minimize the likelihood of the displayed date differing from
// the original date of a shelf survey. As long as surveys are created
// during normal business hours (up to 6pm local), the displayed date should
// be the same as the original survey date where the survey was taken,
// regardless of whether the survey was done in Hawaii, New York, etc.

function formatCreatedDate(utcIsoTimeString: string): string {
  return moment.utc(utcIsoTimeString).utcOffset(-6).format("ddd MMM D YYYY");
}

const connector = connect(
  (state: State) => ({
    files: state.dir_files.content?.data,
    filesPageCount: state.dir_files.content?.pageCount,
    filesPageIndex: state.dir_files.content?.pageIndex,
    batch_download: state.batch_download.content,
    hasError: state.batch_download.error,
    isLoadingDownloads: state.batch_download.loading,
    isLoadingFiles: state.dir_files.loading,
  }),
  {
    createAnalyticsEvent,
    initDIRFiles,
    fetchDIRFiles,
    clearDIRFiles,
    fetchZipForIrisDownload,
  }
);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DirDownloads);
