import cx from "classnames";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link, Redirect, RouteComponentProps, withRouter } from "react-router-dom";

import errorSvg from "assets/img/error-red.svg";
import Icon from "components/Icon";
import Loading from "components/Loading";
import Logo from "components/Logo";
import { Body } from "components/Typography";
import Config, { hasFeature } from "config";
import { groups } from "containers/Navigation";
import { State } from "state";
import { login, postLogin, resetPassword } from "state/auth";
import { UserRole } from "types/roles";
import { isIe } from "utils/helpers";

type ILoginProps = RouteComponentProps<{}> & PropsFromRedux;

class SignIn extends React.Component<ILoginProps> {
  public ieEnabled = hasFeature(Config.FEATURES, "IE");

  public renderLoading = () => {
    return (
      <div className="signin-loading-container">
        <Loading color={"#fff"} />
      </div>
    );
  };

  public renderSigninButton = () => {
    return (
      <>
        <button
          data-qa="signin-button"
          className={cx("signin-button", {
            hide: isIe && !this.ieEnabled,
          })}
          onClick={() => this.props.login()}
        >
          <h2>Sign In</h2>
        </button>
        {(!isIe || (isIe && this.ieEnabled)) && (
          <p style={{ backgroundColor: "#fff", borderRadius: "2px" }}>
            <Link
              style={{
                backgroundColor: "#e13c37",
                color: "#fff",
                padding: "4px 5px 3px 5px",
                margin: "0px 3px 0px 3px",
                fontSize: "small",
              }}
              to="#"
              data-qa="reset-password-link"
              onClick={() => this.props.resetPassword()}
            >
              Reset Password
            </Link>
          </p>
        )}
        {(!isIe || (isIe && this.ieEnabled)) && (
          <p className="legal-text">
            By submitting this form, you agree to be bound by the{" "}
            <a
              className="legal-text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.anheuser-busch.com/terms-conditions.html"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              className="legal-text-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.anheuser-busch.com/privacy-policy.html"
            >
              Privacy Policy
            </a>
            .
            <Link to="/about-us" style={{ textDecoration: "underline" }}>
              About BrewBox360
            </Link>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#fff",
                  maxWidth: "10vw",
                  padding: "3px",
                  borderRadius: "2px",
                }}
              >
                <p
                  style={{
                    backgroundColor: "#e13c37",
                    width: "100%",
                    margin: 0,
                    padding: "2px",
                  }}
                >
                  <a
                    style={{
                      color: "#fff",
                      textDecoration: "none",
                      fontSize: "medium",
                    }}
                    className="legal-text-link"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://brewbox360.zendesk.com/hc/en-us/requests/new"
                  >
                    {` Need BrewBox360 Support? Click here `}
                  </a>
                </p>
              </div>
            </div>
          </p>
        )}
      </>
    );
  };

  public render() {
    const { from = { pathname: "/" } } = this.props.location.state;

    const { user, auth } = this.props;

    let errorMessage: string = "Unable to login. Please check your credentials and try again.";

    if (isIe && !this.ieEnabled) {
      errorMessage =
        "Internet Explorer is not supported by BrewBox360. Relaunch the application in Google Chrome.";
    } else if (typeof auth.error && String(auth.error).indexOf("401") !== -1) {
      // If we get here, that means the B2C login suceeded, but there is no account in the DB.
      errorMessage =
        "Account not activated on BrewBox360. Contact support@brewbox360.com if you believe you should have access.";
    } else if (auth.content !== undefined) {
      errorMessage = auth.content;
    }
    if (!auth.hasError && user.content && auth.content) {
      this.props.postLogin();
      if (from.pathname === "/") {
        for (const { routes } of groups[user.content.role as UserRole]) {
          for (const route of routes) {
            if (
              route.landingPageFor &&
              route.landingPageFor.some(
                (lpr: string) => user && user.content && lpr === user.content.role
              )
            ) {
              return <Redirect to={{ pathname: route.route }} />;
            }
          }
        }
      }

      // redirect to referrer after login unless user explicitly entered a URL
      return <Redirect to={from} />;
    }

    return (
      <section className="signin-container">
        <div
          className={cx("signin-error-container", {
            active: auth.hasError || (isIe && !this.ieEnabled),
          })}
        >
          <Icon src={errorSvg} small={!isIe} />
          <Body type="1" className="signin-error">
            {errorMessage}
          </Body>
        </div>
        <Logo dropShadow={true} style={{ width: 500, height: 150 }} />
        {auth.loading || user.loading ? this.renderLoading() : this.renderSigninButton()}
      </section>
    );
  }
}

const connecter = connect(
  (state: State) => ({
    auth: state.auth,
    user: state.auth_user,
  }),
  { login, resetPassword, postLogin }
);

type PropsFromRedux = ConnectedProps<typeof connecter>;

export default withRouter(connecter(SignIn));
